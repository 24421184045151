import React, { PureComponent } from 'react';

export default class WeWaterIllustration extends PureComponent {
  render() {
    return (
      <svg width="372" height="282" viewBox="0 0 372 282" fill="none" xmlns="http://www.w3.org/2000/svg" id="wewater-illustration">
        <g clipPath="url(#clip0)">
          <path d="M214.373 13.725C217.564 20.5441 210.803 22.4756 203.057 26.1305C195.312 29.7855 189.516 33.7798 186.325 26.9607C183.134 20.1416 180.545 6.60702 191.502 1.43647C202.837 -3.91221 211.182 6.90593 214.373 13.725Z" fill="#2F2E41" />
          <path d="M198.57 27.1354C204.329 27.1354 208.997 22.4474 208.997 16.6645C208.997 10.8816 204.329 6.19363 198.57 6.19363C192.812 6.19363 188.144 10.8816 188.144 16.6645C188.144 22.4474 192.812 27.1354 198.57 27.1354Z" fill="#FFB8B8" />
          <path d="M250.227 63.1008C249.866 62.6276 249.505 62.1629 249.14 61.711C248.669 61.1227 248.195 60.5528 247.718 60.0015C242.313 53.7388 236.565 49.7953 230.236 49.3733H230.227L230.134 49.3647L217.521 61.711L213.233 65.906L213.327 66.1362L215.64 71.9214H256.178C254.352 68.8772 252.365 65.9328 250.227 63.1008Z" fill="#2F2E41" />
          <path d="M187.232 20.8801L190.964 19.1188L190.444 13.7116L192.94 18.1863L194.724 17.3445L194.421 14.1903L195.876 16.8006L209.132 10.5453C207.887 7.8847 205.641 5.8297 202.887 4.8324C200.133 3.83511 197.098 3.97722 194.448 5.22747L192.527 6.13403C189.878 7.38429 187.831 9.64027 186.838 12.4057C185.845 15.1711 185.987 18.2194 187.232 20.8801Z" fill="#2F2E41" />
          <path d="M273.618 210.944H55.9051C54.048 210.942 52.2675 210.2 50.9543 208.881C49.6412 207.563 48.9025 205.775 48.9004 203.91V72.537C48.9019 71.1707 49.443 69.8608 50.4051 68.8947C51.3671 67.9285 52.6715 67.3851 54.032 67.3835H275.402C276.787 67.3851 278.114 67.9379 279.092 68.9208C280.071 69.9036 280.621 71.2362 280.623 72.6261V203.91C280.621 205.775 279.882 207.563 278.569 208.881C277.256 210.2 275.475 210.942 273.618 210.944Z" fill="#F2F2F2" />
          <path d="M62.6977 203.91H266.826C268.628 203.91 270.355 203.191 271.629 201.912C272.903 200.632 273.619 198.897 273.619 197.088V85.585C273.619 83.7759 272.903 82.0409 271.629 80.7617C270.355 79.4825 268.628 78.7639 266.826 78.7639H62.6977C60.8962 78.7639 59.1685 79.4825 57.8947 80.7617C56.6209 82.0409 55.9053 83.7759 55.9053 85.585V197.088C55.9053 198.897 56.6209 200.632 57.8947 201.912C59.1685 203.191 60.8962 203.91 62.6977 203.91Z" fill="white" />
          <path d="M280.525 72.1347H48.8018V68.5534C48.8045 66.6822 49.5461 64.8885 50.8641 63.5658C52.182 62.2431 53.9686 61.4994 55.8319 61.4979H273.495C275.358 61.4994 277.145 62.2431 278.463 63.5658C279.781 64.8885 280.522 66.6822 280.525 68.5534V72.1347Z" fill="#5ABEE6" />
          <path d="M61.5782 68.653C62.5825 68.653 63.3966 67.8354 63.3966 66.8269C63.3966 65.8183 62.5825 65.0008 61.5782 65.0008C60.5739 65.0008 59.7598 65.8183 59.7598 66.8269C59.7598 67.8354 60.5739 68.653 61.5782 68.653Z" fill="white" />
          <path d="M68.4805 68.653C69.4848 68.653 70.299 67.8354 70.299 66.8269C70.299 65.8183 69.4848 65.0008 68.4805 65.0008C67.4762 65.0008 66.6621 65.8183 66.6621 66.8269C66.6621 67.8354 67.4762 68.653 68.4805 68.653Z" fill="white" />
          <path d="M75.3829 68.653C76.3872 68.653 77.2013 67.8354 77.2013 66.8269C77.2013 65.8183 76.3872 65.0008 75.3829 65.0008C74.3786 65.0008 73.5645 65.8183 73.5645 66.8269C73.5645 67.8354 74.3786 68.653 75.3829 68.653Z" fill="white" />
          <path d="M171.13 179.728H152.876C151.807 179.727 150.781 179.3 150.025 178.541C149.269 177.781 148.844 176.752 148.843 175.678V157.346C148.844 156.273 149.269 155.243 150.025 154.484C150.781 153.725 151.807 153.297 152.876 153.296H171.13C172.2 153.297 173.225 153.725 173.981 154.484C174.737 155.243 175.162 156.273 175.163 157.346V175.678C175.162 176.752 174.737 177.781 173.981 178.541C173.225 179.3 172.2 179.727 171.13 179.728ZM152.876 154.149C152.032 154.15 151.222 154.487 150.625 155.086C150.028 155.686 149.693 156.499 149.692 157.346V175.678C149.693 176.526 150.028 177.339 150.625 177.938C151.222 178.537 152.032 178.875 152.876 178.876H171.13C171.975 178.875 172.784 178.537 173.381 177.938C173.978 177.339 174.313 176.526 174.314 175.678V157.346C174.313 156.499 173.978 155.686 173.381 155.086C172.784 154.487 171.975 154.15 171.13 154.149H152.876Z" fill="#CCCCCC" />
          <path d="M167.522 174.612H156.485C156.147 174.612 155.823 174.477 155.584 174.237C155.346 173.998 155.211 173.672 155.211 173.333V159.691C155.211 159.352 155.346 159.027 155.584 158.787C155.823 158.547 156.147 158.412 156.485 158.412H164.482C164.772 158.412 165.054 158.512 165.28 158.694L168.32 161.147C168.469 161.267 168.589 161.418 168.671 161.591C168.754 161.764 168.796 161.953 168.796 162.144V173.333C168.795 173.672 168.661 173.998 168.422 174.237C168.184 174.477 167.86 174.612 167.522 174.612ZM156.485 159.265C156.372 159.265 156.264 159.31 156.184 159.39C156.105 159.47 156.06 159.578 156.06 159.691V173.333C156.06 173.446 156.105 173.555 156.184 173.635C156.264 173.715 156.372 173.76 156.485 173.76H167.522C167.635 173.76 167.743 173.715 167.822 173.635C167.902 173.555 167.947 173.446 167.947 173.333V162.144C167.947 162.08 167.933 162.017 167.905 161.96C167.878 161.902 167.838 161.852 167.788 161.812L164.748 159.359C164.673 159.298 164.579 159.265 164.482 159.265H156.485Z" fill="#CCCCCC" />
          <path d="M165.4 164.381H158.607C158.495 164.381 158.387 164.336 158.307 164.256C158.227 164.176 158.183 164.067 158.183 163.954C158.183 163.841 158.227 163.733 158.307 163.653C158.387 163.573 158.495 163.528 158.607 163.528H165.4C165.512 163.528 165.62 163.573 165.7 163.653C165.779 163.733 165.824 163.841 165.824 163.954C165.824 164.067 165.779 164.176 165.7 164.256C165.62 164.336 165.512 164.381 165.4 164.381Z" fill="#CCCCCC" />
          <path d="M165.4 166.939H158.607C158.495 166.939 158.387 166.894 158.307 166.814C158.227 166.734 158.183 166.625 158.183 166.512C158.183 166.399 158.227 166.291 158.307 166.211C158.387 166.131 158.495 166.086 158.607 166.086H165.4C165.512 166.086 165.62 166.131 165.7 166.211C165.779 166.291 165.824 166.399 165.824 166.512C165.824 166.625 165.779 166.734 165.7 166.814C165.62 166.894 165.512 166.939 165.4 166.939Z" fill="#CCCCCC" />
          <path d="M165.4 169.496H158.607C158.495 169.496 158.387 169.452 158.307 169.372C158.227 169.292 158.183 169.183 158.183 169.07C158.183 168.957 158.227 168.849 158.307 168.769C158.387 168.689 158.495 168.644 158.607 168.644H165.4C165.512 168.644 165.62 168.689 165.7 168.769C165.779 168.849 165.824 168.957 165.824 169.07C165.824 169.183 165.779 169.292 165.7 169.372C165.62 169.452 165.512 169.496 165.4 169.496Z" fill="#CCCCCC" />
          <path d="M214.432 179.728H196.178C195.108 179.727 194.083 179.3 193.327 178.541C192.571 177.781 192.146 176.752 192.145 175.678V157.346C192.146 156.273 192.571 155.243 193.327 154.484C194.083 153.725 195.108 153.297 196.178 153.296H214.432C215.501 153.297 216.526 153.725 217.283 154.484C218.039 155.243 218.464 156.273 218.465 157.346V175.678C218.464 176.752 218.039 177.781 217.283 178.541C216.526 179.3 215.501 179.727 214.432 179.728ZM196.178 154.149C195.333 154.15 194.524 154.487 193.927 155.086C193.33 155.686 192.995 156.499 192.994 157.346V175.678C192.995 176.526 193.33 177.339 193.927 177.938C194.524 178.537 195.333 178.875 196.178 178.876H214.432C215.276 178.875 216.086 178.537 216.682 177.938C217.279 177.339 217.615 176.526 217.616 175.678V157.346C217.615 156.499 217.279 155.686 216.682 155.086C216.086 154.487 215.276 154.15 214.432 154.149H196.178Z" fill="#CCCCCC" />
          <path d="M211.248 171.202H199.361C199.024 171.201 198.7 171.067 198.461 170.827C198.223 170.587 198.088 170.262 198.088 169.923V163.102C198.088 162.763 198.223 162.437 198.461 162.198C198.7 161.958 199.024 161.823 199.361 161.823H211.248C211.586 161.823 211.91 161.958 212.148 162.198C212.387 162.437 212.521 162.763 212.522 163.102V169.923C212.521 170.262 212.387 170.587 212.148 170.827C211.91 171.067 211.586 171.201 211.248 171.202ZM199.361 162.675C199.249 162.675 199.141 162.72 199.061 162.8C198.982 162.88 198.937 162.989 198.937 163.102V169.923C198.937 170.036 198.982 170.144 199.061 170.224C199.141 170.304 199.249 170.349 199.361 170.349H211.248C211.361 170.349 211.469 170.304 211.548 170.224C211.628 170.144 211.673 170.036 211.673 169.923V163.102C211.673 162.989 211.628 162.88 211.548 162.8C211.469 162.72 211.361 162.675 211.248 162.675H199.361Z" fill="#CCCCCC" />
          <path d="M205.305 167.637C205.067 167.637 204.833 167.57 204.63 167.444L198.288 163.463C198.192 163.403 198.125 163.308 198.1 163.198C198.074 163.087 198.094 162.972 198.153 162.876C198.213 162.78 198.308 162.712 198.418 162.687C198.527 162.661 198.642 162.68 198.738 162.74L205.081 166.721C205.148 166.763 205.226 166.785 205.305 166.785C205.385 166.785 205.463 166.763 205.53 166.721L211.873 162.74C211.968 162.68 212.083 162.661 212.193 162.687C212.302 162.712 212.397 162.78 212.457 162.876C212.517 162.972 212.536 163.087 212.511 163.198C212.486 163.308 212.418 163.403 212.323 163.463L205.981 167.444C205.778 167.571 205.544 167.637 205.305 167.637Z" fill="#CCCCCC" />
          <path d="M127.829 179.728H109.574C108.505 179.727 107.48 179.3 106.724 178.541C105.968 177.781 105.542 176.752 105.541 175.678V157.346C105.542 156.273 105.968 155.243 106.724 154.484C107.48 153.725 108.505 153.297 109.574 153.296H127.829C128.898 153.297 129.923 153.725 130.679 154.484C131.435 155.243 131.86 156.273 131.862 157.346V175.678C131.86 176.752 131.435 177.781 130.679 178.541C129.923 179.3 128.898 179.727 127.829 179.728ZM109.574 154.149C108.73 154.15 107.921 154.487 107.324 155.086C106.727 155.686 106.391 156.499 106.39 157.346V175.678C106.391 176.526 106.727 177.339 107.324 177.938C107.921 178.537 108.73 178.875 109.574 178.876H127.829C128.673 178.875 129.482 178.537 130.079 177.938C130.676 177.339 131.012 176.526 131.013 175.678V157.346C131.012 156.499 130.676 155.686 130.079 155.086C129.482 154.487 128.673 154.15 127.829 154.149H109.574Z" fill="#CCCCCC" />
          <path d="M124.645 171.202H112.758C112.42 171.201 112.097 171.067 111.858 170.827C111.619 170.587 111.485 170.262 111.484 169.923V163.102C111.485 162.763 111.619 162.437 111.858 162.198C112.097 161.958 112.42 161.823 112.758 161.823H124.645C124.982 161.823 125.306 161.958 125.545 162.198C125.784 162.437 125.918 162.763 125.918 163.102V169.923C125.918 170.262 125.784 170.587 125.545 170.827C125.306 171.067 124.982 171.201 124.645 171.202ZM112.758 162.675C112.645 162.675 112.537 162.72 112.458 162.8C112.378 162.88 112.334 162.989 112.333 163.102V169.923C112.334 170.036 112.378 170.144 112.458 170.224C112.537 170.304 112.645 170.349 112.758 170.349H124.645C124.757 170.349 124.865 170.304 124.945 170.224C125.024 170.144 125.069 170.036 125.069 169.923V163.102C125.069 162.989 125.024 162.88 124.945 162.8C124.865 162.72 124.757 162.675 124.645 162.675H112.758Z" fill="#CCCCCC" />
          <path d="M122.761 171.202H114.218C114.069 171.202 113.923 171.162 113.794 171.088C113.665 171.013 113.557 170.905 113.483 170.776C113.408 170.646 113.369 170.499 113.369 170.349C113.369 170.199 113.408 170.052 113.483 169.923L116.056 165.446C116.131 165.317 116.238 165.209 116.367 165.134C116.496 165.059 116.643 165.02 116.792 165.02C116.941 165.02 117.087 165.059 117.216 165.134C117.345 165.209 117.452 165.317 117.527 165.446L119.189 168.337L120.152 166.663C120.226 166.533 120.334 166.425 120.463 166.35C120.592 166.276 120.738 166.236 120.887 166.236C121.036 166.236 121.183 166.276 121.312 166.35C121.441 166.425 121.548 166.533 121.623 166.663L123.497 169.923C123.571 170.052 123.611 170.199 123.611 170.349C123.611 170.499 123.571 170.646 123.497 170.776C123.422 170.905 123.315 171.013 123.186 171.088C123.057 171.162 122.91 171.202 122.761 171.202H122.761ZM122.761 170.349L120.887 167.089L119.557 169.403C119.52 169.468 119.466 169.522 119.401 169.559C119.337 169.597 119.264 169.616 119.189 169.616C119.115 169.616 119.041 169.597 118.977 169.559C118.912 169.522 118.859 169.468 118.821 169.403L116.792 165.873L114.218 170.349H122.761Z" fill="#CCCCCC" />
          <path d="M123.372 165.233C123.84 165.233 124.221 164.851 124.221 164.381C124.221 163.91 123.84 163.528 123.372 163.528C122.903 163.528 122.522 163.91 122.522 164.381C122.522 164.851 122.903 165.233 123.372 165.233Z" fill="#CCCCCC" />
          <path d="M326.387 276.32L321.182 276.32L318.706 256.16L326.387 256.16L326.387 276.32Z" fill="#FFB8B8" />
          <path d="M327.713 281.387L310.932 281.386V281.173C310.932 280.311 311.101 279.458 311.429 278.663C311.757 277.867 312.238 277.144 312.845 276.534C313.451 275.925 314.171 275.442 314.964 275.113C315.756 274.783 316.606 274.613 317.464 274.613H317.464L327.714 274.614L327.713 281.387Z" fill="#2F2E41" />
          <path d="M347.612 276.32L342.407 276.32L339.932 256.16L347.613 256.16L347.612 276.32Z" fill="#FFB8B8" />
          <path d="M348.94 281.387L332.158 281.386V281.173C332.158 280.311 332.327 279.458 332.655 278.663C332.984 277.867 333.465 277.144 334.071 276.534C334.678 275.925 335.398 275.442 336.19 275.113C336.983 274.783 337.832 274.613 338.69 274.613H338.691L348.94 274.614L348.94 281.387Z" fill="#2F2E41" />
          <path d="M346.052 267.581L341.232 266.108C340.873 265.999 340.553 265.785 340.314 265.495C340.075 265.204 339.927 264.849 339.888 264.474L335.394 221.703C335.39 221.651 335.368 221.603 335.33 221.567C335.292 221.532 335.242 221.512 335.191 221.512C335.139 221.507 335.088 221.523 335.047 221.555C335.007 221.588 334.981 221.635 334.974 221.687L327.447 263.125C327.401 263.376 327.306 263.616 327.167 263.83C327.027 264.044 326.847 264.228 326.636 264.371C326.425 264.514 326.188 264.613 325.938 264.662C325.688 264.712 325.431 264.711 325.182 264.659L319.022 263.385C318.57 263.29 318.167 263.035 317.888 262.666C317.609 262.297 317.471 261.839 317.501 261.376L322.08 193.458L353.129 190.094L348.515 265.862C348.485 266.349 348.272 266.807 347.918 267.141C347.564 267.476 347.097 267.663 346.611 267.664C346.422 267.664 346.233 267.636 346.052 267.581Z" fill="#2F2E41" />
          <path d="M346.022 134.312C351.781 134.312 356.449 129.624 356.449 123.841C356.449 118.058 351.781 113.37 346.022 113.37C340.264 113.37 335.596 118.058 335.596 123.841C335.596 129.624 340.264 134.312 346.022 134.312Z" fill="#FFB8B8" />
          <path d="M322.032 193.857L328.764 151.288C329.122 149.025 329.956 146.864 331.211 144.949C332.465 143.035 334.111 141.411 336.039 140.185C337.967 138.958 340.133 138.159 342.392 137.839C344.652 137.519 346.953 137.685 349.144 138.328C352.974 139.44 356.253 141.947 358.341 145.357C360.43 148.768 361.177 152.838 360.437 156.772L353.393 194.788L322.032 193.857Z" fill="#5ABEE6" />
          <path d="M348.901 201.473C349.422 201.128 349.861 200.672 350.187 200.138C350.514 199.604 350.719 199.004 350.79 198.382C350.861 197.759 350.795 197.128 350.596 196.534C350.398 195.94 350.073 195.396 349.643 194.942L356.603 181.406L348.764 182.246L343.356 194.971C342.644 195.747 342.24 196.759 342.221 197.814C342.202 198.869 342.569 199.894 343.253 200.696C343.936 201.497 344.889 202.019 345.93 202.162C346.971 202.306 348.028 202.061 348.901 201.473V201.473Z" fill="#FFB8B8" />
          <path d="M350.392 192.555C350.007 192.5 349.647 192.329 349.362 192.064L346.698 189.593C346.411 189.324 346.212 188.974 346.129 188.589C346.046 188.203 346.083 187.802 346.235 187.438L351.558 174.768L350.802 149.735C350.911 148.93 351.176 148.155 351.584 147.453C351.991 146.752 352.531 146.137 353.175 145.646C353.819 145.154 354.552 144.795 355.335 144.588C356.117 144.381 356.931 144.331 357.733 144.441C358.534 144.551 359.306 144.818 360.004 145.228C360.702 145.637 361.314 146.181 361.803 146.828C362.292 147.474 362.649 148.212 362.854 148.997C363.059 149.783 363.108 150.601 362.998 151.406L364.289 167.142C364.681 171.918 363.333 176.675 360.495 180.527L352.194 191.796C352.034 192.012 351.831 192.193 351.597 192.325C351.364 192.458 351.105 192.539 350.838 192.565C350.689 192.579 350.54 192.576 350.392 192.555Z" fill="#5ABEE6" />
          <path d="M285.978 118.136C286.092 118.752 286.339 119.336 286.701 119.846C287.064 120.356 287.533 120.78 288.077 121.088C288.62 121.397 289.224 121.581 289.847 121.63C290.469 121.678 291.094 121.589 291.678 121.368L301.377 133.083L303.663 125.506L294.102 115.543C293.668 114.582 292.898 113.815 291.938 113.386C290.978 112.957 289.895 112.896 288.894 113.216C287.892 113.535 287.042 114.213 286.505 115.12C285.968 116.026 285.78 117.1 285.978 118.136Z" fill="#FFB8B8" />
          <path d="M293.575 122.992C293.775 122.657 294.072 122.391 294.426 122.23L297.731 120.731C298.089 120.57 298.488 120.522 298.874 120.596C299.259 120.67 299.613 120.86 299.887 121.143L309.43 131.004L332.68 140.066C333.375 140.48 333.983 141.028 334.468 141.678C334.953 142.328 335.305 143.068 335.505 143.855C335.705 144.642 335.749 145.461 335.634 146.265C335.518 147.069 335.246 147.842 334.833 148.54C334.42 149.238 333.874 149.848 333.226 150.334C332.578 150.82 331.841 151.173 331.057 151.373C330.273 151.573 329.458 151.616 328.658 151.499C327.857 151.382 327.088 151.108 326.393 150.693L311.459 145.751C306.927 144.252 303.091 141.15 300.665 137.024L293.568 124.954C293.432 124.722 293.345 124.464 293.315 124.196C293.285 123.928 293.311 123.657 293.392 123.4C293.436 123.257 293.498 123.12 293.575 122.992Z" fill="#5ABEE6" />
          <path opacity="0.1" d="M354.48 160.148L354.055 173.791L346.234 187.438L352.764 172.23L354.48 160.148Z" fill="black" />
          <path d="M344.828 125.809C344.173 125.894 343.509 125.738 342.96 125.369C342.412 125.001 342.015 124.444 341.843 123.804C341.678 123.067 341.316 122.389 340.796 121.842C340.276 121.296 339.618 120.902 338.892 120.703C336.98 120.264 334.642 121.168 333.24 119.713C332.94 119.381 332.723 118.982 332.607 118.549C332.491 118.116 332.479 117.661 332.572 117.222C332.83 115.808 333.837 114.74 334.933 113.885C337.24 112.095 340.032 111.048 342.943 110.879C344.449 110.836 345.957 110.911 347.451 111.105C349.016 111.204 350.564 111.488 352.063 111.951C353.771 112.598 355.288 113.667 356.476 115.059C357.663 116.451 358.482 118.121 358.856 119.915C359.199 121.714 359.146 123.567 358.702 125.344C358.258 127.121 357.433 128.779 356.285 130.202C354.911 131.921 353.138 133.276 351.123 134.149C350.642 134.377 350.092 134.405 349.592 134.227C348.336 133.726 348.541 132.164 349.114 131.123C349.726 130.008 350.669 128.929 350.176 127.572C349.929 126.943 349.455 126.43 348.849 126.137C347.601 125.498 346.205 125.649 344.863 125.805L344.828 125.809Z" fill="#2F2E41" />
          <path d="M282.494 131.722H153.438C151.581 131.72 149.801 130.978 148.488 129.659C147.174 128.34 146.436 126.552 146.434 124.687V96.9764C146.436 95.1115 147.174 93.3235 148.488 92.0048C149.801 90.686 151.581 89.9442 153.438 89.9421H282.494C284.351 89.9443 286.132 90.6861 287.445 92.0048C288.758 93.3235 289.497 95.1115 289.499 96.9764V124.687C289.497 126.552 288.758 128.34 287.445 129.659C286.132 130.978 284.351 131.719 282.494 131.722Z" fill="white" />
          <path d="M282.493 131.935H153.438C151.524 131.933 149.69 131.168 148.337 129.81C146.984 128.451 146.223 126.609 146.221 124.687V96.9765C146.223 95.055 146.984 93.2128 148.337 91.8541C149.69 90.4954 151.524 89.7312 153.438 89.729H282.493C284.407 89.7312 286.241 90.4954 287.594 91.8541C288.947 93.2128 289.708 95.055 289.71 96.9765V124.687C289.708 126.609 288.947 128.451 287.594 129.81C286.241 131.168 284.407 131.933 282.493 131.935ZM153.438 90.5816C151.749 90.5836 150.131 91.2579 148.937 92.4568C147.743 93.6556 147.072 95.281 147.07 96.9765V124.687C147.072 126.383 147.743 128.008 148.937 129.207C150.131 130.406 151.749 131.08 153.438 131.082H282.493C284.182 131.08 285.8 130.406 286.994 129.207C288.188 128.008 288.859 126.383 288.861 124.687V96.9765C288.859 95.281 288.188 93.6556 286.994 92.4568C285.8 91.2579 284.182 90.5836 282.493 90.5816H153.438Z" fill="#3F3D56" />
          <path d="M266.786 124.901H169.145C167.288 124.898 165.508 124.157 164.195 122.838C162.881 121.519 162.143 119.731 162.141 117.866V103.371C162.143 101.506 162.881 99.7184 164.195 98.3997C165.508 97.0809 167.288 96.3391 169.145 96.337H266.786C268.643 96.3391 270.424 97.0809 271.737 98.3997C273.05 99.7184 273.789 101.506 273.791 103.371V117.866C273.789 119.731 273.05 121.519 271.737 122.838C270.424 124.157 268.643 124.898 266.786 124.901Z" fill="#F2F2F2" />
          <path d="M190.157 117.088C194.784 117.088 198.534 113.322 198.534 108.676C198.534 104.03 194.784 100.263 190.157 100.263C185.531 100.263 181.78 104.03 181.78 108.676C181.78 113.322 185.531 117.088 190.157 117.088Z" fill="#FF6584" />
          <path d="M260.719 124.052L248.895 114.093L237.275 104.306C236.968 104.048 236.58 103.907 236.18 103.907C235.78 103.908 235.393 104.051 235.087 104.31L223.505 114.142L220.676 116.544L216.41 112.951L203.446 102.032C203.14 101.774 202.752 101.633 202.352 101.634C201.952 101.634 201.564 101.777 201.259 102.037L188.337 113.005L175.212 124.146C175.146 124.203 175.098 124.278 175.076 124.363C175.053 124.447 175.058 124.537 175.088 124.619C175.118 124.701 175.172 124.772 175.243 124.822C175.315 124.872 175.4 124.899 175.487 124.898L202.396 124.846L210.914 124.83L210.831 124.901L236.22 124.851L260.447 124.805C260.534 124.804 260.619 124.777 260.69 124.727C260.761 124.677 260.815 124.606 260.845 124.524C260.875 124.441 260.879 124.352 260.856 124.268C260.833 124.183 260.786 124.108 260.719 124.052Z" fill="#3F3D56" />
          <path d="M371.575 282H290.066C289.954 282 289.846 281.955 289.766 281.875C289.686 281.795 289.642 281.687 289.642 281.574C289.642 281.461 289.686 281.352 289.766 281.272C289.846 281.192 289.954 281.147 290.066 281.147H371.575C371.688 281.147 371.796 281.192 371.875 281.272C371.955 281.352 372 281.461 372 281.574C372 281.687 371.955 281.795 371.875 281.875C371.796 281.955 371.688 282 371.575 282Z" fill="#CCCCCC" />
          <path d="M256.438 56.237C256.099 55.6837 255.651 55.2055 255.122 54.8317C254.593 54.4579 253.993 54.1963 253.36 54.0628C253.128 54.0138 252.892 53.9838 252.655 53.9732L220.311 24.9322C219.675 24.2764 218.828 23.8698 217.921 23.7854C217.742 23.7648 217.561 23.7591 217.381 23.7684C216.635 23.8024 215.915 24.0541 215.309 24.4926C214.703 24.9312 214.237 25.5376 213.969 26.2377C213.7 26.9378 213.641 27.7012 213.798 28.4347C213.954 29.1682 214.32 29.84 214.851 30.3678L227.235 41.2092L247.569 59.0081C247.586 59.3433 247.636 59.6761 247.718 60.0014C247.961 60.944 248.484 61.79 249.218 62.4272C249.951 63.0645 250.859 63.4629 251.823 63.5697C252.702 63.6728 253.592 63.5251 254.392 63.1434C255.025 62.8458 255.587 62.4138 256.039 61.8772C256.085 61.8218 256.128 61.7706 256.17 61.711C256.764 60.9341 257.108 59.994 257.155 59.0159C257.203 58.0379 256.953 57.0685 256.438 56.237H256.438Z" fill="#FFB8B8" />
          <path d="M201.113 84.2251L200.812 71.9214L200.557 61.711L200.383 54.5659L200.039 40.638C199.971 39.8922 199.688 39.1826 199.225 38.5955C198.762 38.0085 198.139 37.5695 197.432 37.3322C196.725 37.0948 195.964 37.0694 195.243 37.259C194.521 37.4486 193.87 37.8449 193.37 38.3998C193.252 38.5265 193.145 38.6635 193.051 38.809C192.529 39.5607 192.293 40.4765 192.389 41.3883L193.145 61.711L193.523 71.9214L194.006 84.9455C193.867 85.1369 193.741 85.3378 193.629 85.5467C193.327 86.1215 193.144 86.752 193.093 87.3999C193.041 88.0477 193.121 88.6994 193.327 89.3153C193.543 89.9612 193.892 90.5539 194.353 91.0543C194.813 91.5548 195.374 91.9516 195.998 92.2186C196.622 92.4857 197.295 92.6169 197.974 92.6037C198.652 92.5904 199.32 92.433 199.933 92.1418C200.645 91.8036 201.264 91.2944 201.733 90.6592C202.203 90.024 202.51 89.2823 202.627 88.4998C202.743 87.7172 202.666 86.9178 202.402 86.1723C202.138 85.4267 201.696 84.758 201.113 84.2251H201.113Z" fill="#FFB8B8" />
          <path d="M230.235 49.3732L227.234 41.2092L222.377 27.9847C222.027 27.0225 221.465 26.1519 220.734 25.4375C220.003 24.723 219.121 24.1832 218.153 23.8579C218.077 23.8323 217.996 23.8067 217.92 23.7854C216.983 23.4997 215.995 23.4212 215.025 23.5551C214.055 23.6891 213.125 24.0323 212.299 24.5613L195.942 34.9806C194.444 35.9326 193.373 37.4322 192.956 39.1624C192.538 40.8926 192.807 42.7181 193.705 44.2531C195.75 47.8051 197.979 51.2474 200.383 54.5658C202.289 57.1706 205.838 57.4477 206.454 61.711H218.514L230.724 50.7033L230.235 49.3732Z" fill="#CCCCCC" />
          <path d="M42.9473 149.15C48.3801 149.15 52.7843 144.727 52.7843 139.272C52.7843 133.816 48.3801 129.393 42.9473 129.393C37.5145 129.393 33.1104 133.816 33.1104 139.272C33.1104 144.727 37.5145 149.15 42.9473 149.15Z" fill="#A0616A" />
          <path d="M67.0966 132.472C67.1936 132.549 67.2875 132.631 67.3785 132.718C67.8304 133.146 68.1873 133.664 68.4257 134.24C68.6641 134.816 68.7787 135.435 68.762 136.058C68.7453 136.682 68.5976 137.294 68.3287 137.856C68.0597 138.418 67.6756 138.917 67.2014 139.319C67.0909 139.412 66.9756 139.5 66.856 139.581L67.5217 162.152C67.5407 162.891 67.3219 163.616 66.8978 164.221C66.4738 164.825 65.8671 165.276 65.1677 165.506L65.1271 165.515L46.8773 167.467C46.3812 167.52 45.8795 167.473 45.4012 167.33C44.923 167.188 44.4776 166.951 44.0909 166.635C43.7041 166.318 43.3837 165.928 43.148 165.486C42.9124 165.044 42.7662 164.56 42.7179 164.061C42.6696 163.563 42.7202 163.059 42.8667 162.58C43.0132 162.101 43.2528 161.656 43.5716 161.271C43.8903 160.885 44.2819 160.567 44.7239 160.334C45.1658 160.102 45.6492 159.959 46.1462 159.915L59.1555 158.765L61.4053 139.217C61.2582 139.087 61.1204 138.945 60.9929 138.795C60.2875 137.957 59.9175 136.885 59.9548 135.788C59.9921 134.691 60.4341 133.647 61.1948 132.859C61.9555 132.071 62.9807 131.595 64.0714 131.523C65.162 131.452 66.2402 131.79 67.0966 132.472H67.0966Z" fill="#A0616A" />
          <path d="M59.1483 276.757H64.0588L66.3945 257.737H59.1475L59.1483 276.757Z" fill="#A0616A" />
          <path d="M57.8965 275.147L67.5663 275.146H67.5667C68.376 275.146 69.1773 275.307 69.925 275.618C70.6727 275.928 71.352 276.384 71.9243 276.959C72.4965 277.534 72.9504 278.216 73.2601 278.967C73.5698 279.717 73.7292 280.522 73.7292 281.335V281.536L57.8968 281.537L57.8965 275.147Z" fill="#2F2E41" />
          <path d="M10.3438 269.939L14.5627 272.462L26.2597 257.32L20.0327 253.597L10.3438 269.939Z" fill="#A0616A" />
          <path d="M10.0881 267.912L18.3968 272.88L18.3971 272.881C19.0924 273.296 19.6994 273.846 20.1834 274.497C20.6674 275.148 21.0188 275.889 21.2177 276.677C21.4167 277.464 21.4591 278.284 21.3427 279.088C21.2263 279.892 20.9532 280.666 20.5392 281.364L20.4367 281.537L6.83301 273.403L10.0881 267.912Z" fill="#2F2E41" />
          <path d="M65.2171 270.039H59.7188C59.2736 270.04 58.8439 269.875 58.5129 269.576C58.1818 269.277 57.973 268.865 57.9266 268.42L52.3835 216.095C52.3695 215.963 52.3128 215.84 52.222 215.744C52.1313 215.647 52.0115 215.584 51.8813 215.563C51.7511 215.542 51.6176 215.565 51.5014 215.627C51.3852 215.69 51.2929 215.79 51.2385 215.91L41.2386 238.147L23.5344 267.138C23.3053 267.509 22.9505 267.786 22.535 267.916C22.1196 268.046 21.6713 268.022 21.2721 267.848L14.1994 264.723C13.9656 264.619 13.7563 264.467 13.5854 264.276C13.4145 264.086 13.286 263.861 13.2083 263.616C13.1307 263.372 13.1056 263.113 13.1349 262.859C13.1642 262.604 13.247 262.358 13.3781 262.138L29.6531 234.771L34.6446 211.713C33.0747 201.714 39.8047 186.848 39.8729 186.699L39.9063 186.626L58.4443 179.35L58.5372 179.464C65.8481 203.557 70.303 229.485 67.0136 268.381C66.9731 268.832 66.7666 269.252 66.4344 269.558C66.1022 269.865 65.6683 270.036 65.2171 270.039Z" fill="#2F2E41" />
          <path d="M39.1601 189.062L39.0738 188.88C39.0325 188.793 34.9105 180.062 31.7329 170.731C31.1222 168.926 30.8913 167.014 31.0549 165.116C31.2185 163.217 31.773 161.373 32.6832 159.701C33.6053 158.011 34.8667 156.532 36.3877 155.358C37.9086 154.183 39.6558 153.339 41.5187 152.878C44.7527 152.099 48.1576 152.513 51.113 154.045C54.0684 155.576 56.3777 158.123 57.62 161.221C60.1405 167.55 59.3372 174.306 58.5813 179.619L58.5662 179.726L58.4698 179.772L39.1601 189.062Z" fill="#3F3D56" />
          <path d="M54.8738 133.691H39.2539V126.854C42.6823 125.486 46.0372 124.323 48.0651 126.854C49.8709 126.854 51.6027 127.574 52.8796 128.857C54.1565 130.139 54.8738 131.878 54.8738 133.691V133.691Z" fill="#2F2E41" />
          <path d="M38.3891 125.647C29.0512 125.647 26.4375 137.402 26.4375 144.033C26.4375 147.731 28.1029 149.053 30.7199 149.501L31.6441 144.551L33.8088 149.714C34.5439 149.718 35.3162 149.703 36.1168 149.688L36.8508 148.171L38.4875 149.661C45.0425 149.671 50.3405 150.631 50.3405 144.033C50.3405 137.402 48.0483 125.647 38.3891 125.647Z" fill="#2F2E41" />
          <path d="M81.9335 282H0.424526C0.311935 282 0.203954 281.955 0.12434 281.875C0.0447264 281.795 0 281.687 0 281.574C0 281.461 0.0447264 281.352 0.12434 281.272C0.203954 281.192 0.311935 281.147 0.424526 281.147H81.9335C82.0461 281.147 82.1541 281.192 82.2337 281.272C82.3133 281.352 82.358 281.461 82.358 281.574C82.358 281.687 82.3133 281.795 82.2337 281.875C82.1541 281.955 82.0461 282 81.9335 282Z" fill="#CCCCCC" />
          <path d="M83.4663 141.74H65.2117C64.1425 141.739 63.1174 141.312 62.3613 140.553C61.6052 139.793 61.1799 138.764 61.1787 137.69V119.358C61.1799 118.285 61.6052 117.255 62.3613 116.496C63.1174 115.737 64.1425 115.31 65.2117 115.308H83.4663C84.5356 115.31 85.5607 115.737 86.3167 116.496C87.0728 117.255 87.4981 118.285 87.4993 119.358V137.69C87.4981 138.764 87.0728 139.793 86.3167 140.553C85.5607 141.312 84.5356 141.739 83.4663 141.74Z" fill="white" />
          <path d="M83.4663 141.74H65.2117C64.1425 141.739 63.1174 141.312 62.3613 140.553C61.6052 139.793 61.1799 138.764 61.1787 137.69V119.358C61.1799 118.285 61.6052 117.255 62.3613 116.496C63.1174 115.737 64.1425 115.31 65.2117 115.308H83.4663C84.5356 115.31 85.5607 115.737 86.3167 116.496C87.0728 117.255 87.4981 118.285 87.4993 119.358V137.69C87.4981 138.764 87.0728 139.793 86.3167 140.553C85.5607 141.312 84.5356 141.739 83.4663 141.74ZM65.2117 116.161C64.3676 116.162 63.5583 116.499 62.9614 117.099C62.3645 117.698 62.0287 118.511 62.0278 119.358V137.69C62.0287 138.538 62.3645 139.351 62.9614 139.95C63.5583 140.55 64.3676 140.887 65.2117 140.888H83.4663C84.3105 140.887 85.1198 140.55 85.7167 139.95C86.3136 139.351 86.6493 138.538 86.6503 137.69V119.358C86.6493 118.511 86.3136 117.698 85.7167 117.099C85.1198 116.499 84.3105 116.162 83.4663 116.161H65.2117Z" fill="#3F3D56" />
          <path d="M80.2824 134.28H68.3956C68.058 134.279 67.7343 134.144 67.4955 133.905C67.2567 133.665 67.1224 133.34 67.1221 133.001V124.048C67.1224 123.709 67.2567 123.384 67.4955 123.144C67.7343 122.904 68.058 122.769 68.3956 122.769H71.7419C71.9595 122.768 72.1737 122.824 72.3637 122.93C72.5538 123.037 72.7132 123.191 72.8266 123.377L73.7598 124.901H80.2824C80.62 124.901 80.9438 125.036 81.1825 125.276C81.4213 125.515 81.5556 125.84 81.5559 126.179V133.001C81.5556 133.34 81.4213 133.665 81.1825 133.905C80.9438 134.144 80.62 134.279 80.2824 134.28Z" fill="#5ABEE6" />
          <path d="M79.8955 138.803C79.9549 138.912 80.0098 139.024 80.0601 139.139C80.3114 139.71 80.4399 140.327 80.4372 140.95C80.4345 141.573 80.3007 142.189 80.0445 142.757C79.7882 143.325 79.4154 143.832 78.9504 144.245C78.4854 144.658 77.9388 144.968 77.3462 145.154C77.2084 145.197 77.0684 145.233 76.9268 145.261L68.8186 166.323C68.5505 167.011 68.0686 167.595 67.4443 167.986C66.82 168.378 66.0867 168.557 65.3531 168.497L65.3123 168.489L47.7384 163.176C47.2606 163.032 46.816 162.794 46.4303 162.476C46.0447 162.158 45.7256 161.766 45.4914 161.324C45.2573 160.881 45.1128 160.396 45.0663 159.897C45.0197 159.398 45.0721 158.895 45.2203 158.417C45.3685 157.938 45.6097 157.494 45.9298 157.11C46.25 156.725 46.6428 156.408 47.0855 156.177C47.5283 155.946 48.0123 155.806 48.5095 155.763C49.0067 155.721 49.5074 155.778 49.9825 155.932L62.417 159.941L72.0437 142.802C71.9586 142.624 71.8861 142.44 71.8266 142.252C71.5005 141.204 71.5737 140.073 72.032 139.076C72.4902 138.08 73.3009 137.29 74.3065 136.86C75.3121 136.43 76.4409 136.391 77.4737 136.75C78.5066 137.109 79.3697 137.84 79.8955 138.803H79.8955Z" fill="#A0616A" />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="372" height="282" fill="white" />
          </clipPath>
        </defs>
      </svg>
    );
  }
}
